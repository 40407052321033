import React, { useEffect, useState } from "react";
import _ from "lodash";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import Lottie from "react-lottie";

import Layout from "../../components/Layout";
import Dropdown from "../../components/Dropdown";
import PaginatedGridPoi from "../../components/PaginationGridPoi";
import { AdsignMapContext } from "../../adsignMapProvider";
import * as animationData from "../../assets/lottie/loading.json";
import { getInformationAsset } from "../../helper";
import DetailPoi from "../../components/DetailPoi";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";
import { Helmet } from "react-helmet-async";

const Directory = observer(() => {
  const mapStore = React.useContext(AdsignMapContext);
  const location = useLocation();

  const [pois, setPois] = useState([]);
  const [categoryPois, setCategoryPois] = useState([]);
  const [floors, setFloors] = useState([]);
  const [search, setSearch] = useState("");
  const [query, setQuery] = useState("");
  const [directoryPageImg, setDirectoryPageImg] = useState(null);
  const [predefinedPoi, setPredefinedPoi] = useState(null);
  const [valueToShowInCategory, setValueToShowInCategory] = useState();
  const [initialLetter, setInitialLetter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState({
    name: "All Level",
  });
  const [selectedSort, setSelectedSort] = useState({
    name: "A to Z",
    value: "ascending",
  });

  const [config, setConfig] = useState([]);

  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const sortBy = [
    {
      name: "A to Z",
      value: "ascending",
    },
    {
      name: "Z to A",
      value: "descending",
    },
  ];

  useEffect(() => {
    logAnalyticsEvent("destination", {
      page_title: `directory`,
    });
  }, []);

  // to prevent body below detail poi can be scrolled
  useEffect(() => {
    if (predefinedPoi) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [predefinedPoi]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setQuery(queryParams?.get("query")); // to differentitate wether user click search or not. used in search query
    setSearch(queryParams?.get("query")); // for input text value
    const categoryIdParams = queryParams?.get("categoryId");
    const poiIdParams = queryParams?.get("poiId");
    if (categoryIdParams) {
      const selectedCat = mapStore?.categoryPois?.filter(
        (item) => item._id === categoryIdParams
      );
      setSelectedCategory(selectedCat);
      const element = document.getElementById("directoryFilter");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
    if (poiIdParams) {
      const filteredPoi = mapStore?.pois?.find(
        (poi) => poi._id === poiIdParams
      );
      setPredefinedPoi(filteredPoi);
      const element = document.getElementById("directoryFilter");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [mapStore?.categoryPois]);

  useEffect(() => {
    fetch("/config.json")
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error("Error loading config.json:", error));
  }, []);

  // Fetch data from adsign
  useEffect(() => {
    if (mapStore && mapStore.information && mapStore.information.length > 0) {
      const directoryData = mapStore.information.filter((info) => {
        return info.name.toLowerCase().includes("store directory page ");
      });
      const landingPageData = directoryData.filter((data) => {
        return data.name.toLowerCase().includes("header - image");
      });

      if (landingPageData && landingPageData.length > 0) {
        setDirectoryPageImg(landingPageData[0].files[0].logo);
      }
    }
  }, [mapStore, mapStore.information]);

  useEffect(() => {
    if (directoryPageImg)
      console.log("Directory page img nya", directoryPageImg);
  }, [directoryPageImg]);

  useEffect(() => {
    if (mapStore.pois) {
      // setIsLoading(true);
      const poiList = mapStore.pois
        .filter((poi) => {
          if (query) {
            const tag = mapStore?.tags?.filter((tag) =>
              tag.name.toLowerCase().includes(query.toLowerCase())
            );
            return (
              poi.name.toLowerCase().includes(query.toLowerCase()) ||
              (poi.categoryName &&
                poi.categoryName.toLowerCase().includes(query.toLowerCase())) ||
              (tag && tag.some((item) => poi.tags.includes(item._id)))
            );
          } else {
            return true;
          }
        })
        .filter((poi) => {
          if (initialLetter) {
            return poi.name[0].toLowerCase() === initialLetter.toLowerCase();
          } else {
            return true;
          }
        })
        .filter((poi) => {
          return poi.categoryName !== "Amenities";
        })
        .filter((poi) => {
          if (
            selectedCategory.length > 0 &&
            !selectedCategory.some(
              (category) => category.name.toLowerCase() === "all category"
            )
          ) {
            return (
              poi.categoryName &&
              selectedCategory.some(
                (category) =>
                  category.name.toLowerCase() === poi.categoryName.toLowerCase()
              )
            );
          } else {
            return true;
          }
        })
        .filter((poi) => {
          if (selectedLevel.name && selectedLevel.name !== "All Level") {
            return (
              poi.floorName &&
              poi.floorName.toLowerCase() === selectedLevel.name.toLowerCase()
            );
          } else {
            return true;
          }
        });

      if (selectedSort.value == "descending") {
        poiList.sort((a, b) => {
          return b.name.localeCompare(a.name);
        });
      } else if (selectedSort.value == "ascending") {
        poiList.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      }

      setPois(poiList);
    }
  }, [
    mapStore.pois,
    query,
    initialLetter,
    selectedCategory,
    selectedLevel,
    selectedSort,
  ]);

  useEffect(() => {
    if (mapStore.categoryPois) {
      const tempFilteredCategory = mapStore?.categoryPois?.filter(
        (item) => item.name !== "Amenities"
      );
      const filteredCategory = tempFilteredCategory.filter((category) => {
        return mapStore?.pois.some((poi) =>
          poi.categoryName.includes(category.name)
        );
      });
      setCategoryPois([
        {
          name: "All Category",
        },
        ...filteredCategory,
      ]);
    }
  }, [mapStore.categoryPois]);

  useEffect(() => {
    if (mapStore.floors) {
      const sortedFloor = _.orderBy(
        mapStore.floors.map((floor) => {
          const x = floor;
          const splitedText = x.name.split("");
          const orderNumber =
            splitedText[0] === "B"
              ? parseInt(-splitedText[1])
              : parseInt(splitedText[1]);
          return {
            ...x,
            order_number: orderNumber,
          };
        }),
        (level) => level.order_number,
        "order_number"
      ).reverse();
      setFloors([
        {
          name: "All Level",
        },
        ...sortedFloor,
      ]);
    }
  }, [mapStore.floors]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.length > 0) {
      const collectionSelectedCategoryName = selectedCategory
        .map((obj) => obj.name)
        .sort((a, b) => a.localeCompare(b))
        .join(", ");

      setValueToShowInCategory(collectionSelectedCategoryName);
    } else {
      setValueToShowInCategory("All Category");
    }
  }, [selectedCategory]);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);

    setTimeout(() => {
      setQuery(event.target.value);
    }, 1000);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="Check out our stores" />
      </Helmet>
      <Layout
        title={"Store Directory"}
        headerUrl={directoryPageImg && getInformationAsset(directoryPageImg)}
        headerHeight={"h-[228px] md:h-[416px]"}
      >
        <div className="h-full">
          {/* content */}
          <div
            className={`flex p-4 md:p-10 w-full flex-col ${
              pois.length < 1 && "h-[600px] md:h-[650px]"
            }`}
          >
            {/* content top / filter */}
            <div className="flex flex-col md:flex-row justify-between w-full items-center">
              <div
                id="directoryFilter"
                className="flex flex-col md:flex-row gap-4 w-full"
              >
                <div className="flex flex-row justify-between items-center rounded-full border border-gray-300 pl-4 pr-2 w-full md:w-[320px] h-12 bg-[#F7F7F7]">
                  <input
                    type="text"
                    placeholder="I'm Looking for"
                    className="bg-[#F7F7F7] focus:outline-none focus:border-transparent w-full"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  <img
                    src={require("../../assets/search.png")}
                    className="h-8 w-8"
                  />
                </div>
                <Dropdown
                  options={categoryPois}
                  selected={selectedCategory}
                  setSelected={setSelectedCategory}
                  placeHolder={"All Category"}
                  title={"Category"}
                  multiSelect={true}
                  className={"w-full md:w-[290px]"}
                  valueToShow={valueToShowInCategory}
                />
                <Dropdown
                  options={floors}
                  selected={selectedLevel}
                  setSelected={setSelectedLevel}
                  placeHolder={"All Level"}
                  title={"Level"}
                  className={"w-full md:w-[290px]"}
                />
                <Dropdown
                  options={sortBy}
                  selected={selectedSort}
                  setSelected={setSelectedSort}
                  placeHolder={"Default"}
                  title={"Sort By"}
                  className={"w-full md:w-[290px]"}
                />
              </div>
              {/* <p className="text-[#787878] font-semibold text-2xl">
              <span key={"#"} onClick={() => setInitialLetter("")}>
                #
              </span>{" "}
              {alphabet.map((letter) => (
                <span
                  key={letter}
                  className={`ml-2 cursor-pointer ${
                    letter === initialLetter && "text-[#50A85A]"
                  }`}
                  onClick={() => setInitialLetter(letter)}
                >
                  {letter}
                </span>
              ))}
            </p> */}
            </div>
            {isLoading ? (
              <div className="flex flex-row items-center justify-center ">
                <div className="w-[200px] h-[200px]">
                  <Lottie options={defaultOptions} />
                </div>
              </div>
            ) : (
              <PaginatedGridPoi
                pois={pois}
                category={categoryPois}
                assetUrl={config.assetUrl}
              />
            )}
          </div>

          {predefinedPoi && (
            <DetailPoi
              onClose={() => {
                setPredefinedPoi(null);
              }}
              poi={predefinedPoi}
            />
          )}
        </div>
      </Layout>
    </>
  );
});

export default Directory;
