/* eslint-disable react/jsx-indent-props */
import React, { useContext, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import Header from "../Header";
import footerBackground from "../../assets/footer-background.png";
import fbIcon from "../../assets/icons/fb.svg";
import igIcon from "../../assets/icons/instagram.svg";
import tiktokIcon from "../../assets/icons/tiktok.svg";
import backToTop from "../../assets/btn-back-top.png";
import bgContactUs from "../../assets/bg-about-us-contact-us.png";
import { useMatch } from "react-router-dom";
import TextInput from "../TextInput";
import SelectInput from "../SelectInput";
import FormConsentCheckbox from "../FormConsentCheckbox";
import FormSubmitButton from "../FormSubmitButton";
import { AdsignMapContext } from "../../adsignMapProvider";
import { extractTextFromTags, getInformationAsset } from "../../helper";
import { ReactComponent as FooterLogo } from "../../assets/icons/footer-logo.svg";
import TitleSection from "../TitleSection";
import ImageLoader from "../ImageLoader";
import { mapConfig } from "../../constants/mapConstant";
import captcha from "../../assets/captcha.png";
import PrivacyPolicy from "../PrivacyPolicy";
import { sendEmail } from "../../axiosActions";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaConstant, getRecipient } from "../../constants/appConstant";
import ModalMailSent from "../../components/ModalMailSent";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/loading.json";
import TermsConditions from "../TermsConditions";

const DefaultContactUs = ({
  contactUsEmail,
  contactUsPhone,
  contactUsSupportingText,
}) => {
  return (
    <div
      className="w-full"
      style={{
        backgroundImage: `url(${footerBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col py-10 px-8 lg:pb-28 lg:pt-20 lg:px-[120px]">
        <TitleSection
          title={"Contact Us"}
          color={"border-[#D05354]"}
          className={"mb-4 md:mb-8"}
        />
        <div className="flex flex-col gap-8 lg:flex-row justify-between">
          <div className="flex flex-1 flex-col lg:w-[50%]">
            <span className="text-2xl font-bold flex lg:hidden leading-[130%] tracking-tighter">
              {extractTextFromTags(contactUsSupportingText, "h1") ??
                "We are always happy to assist you"}
            </span>
            <span className="text-6xl font-bold hidden lg:flex leading-[130%] tracking-tighter">
              {extractTextFromTags(contactUsSupportingText, "h1") ??
                "We are always happy to assist you"}
            </span>
          </div>

          <div className="flex flex-1 flex-col lg:flex-row lg:justify-around">
            <div className="flex flex-col">
              <span className="text-xs lg:text-xl font-semibold mb-2 lg:mb-4">
                Email Address
              </span>
              <span className="text-xs lg:text-xl mb-2 lg:mb-4 w-full text-wrap overflow-hidden">
                {contactUsEmail ?? "help@info.com"}
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-xs lg:text-xl font-semibold mb-2 lg:mb-4">
                Number
              </span>
              <span className="text-xs lg:text-xl mb-2 md:mb-4">
                {contactUsPhone ?? "(65) 13 4633"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutUsContactUs = ({
  contactUsEmail,
  contactUsPhone,
  contactUsSupportingText,
}) => {
  const recaptcha = useRef();
  const [helpType, setHelpType] = useState("General Enquiry");
  const [showModalAfterSentMail, setShowModalAfterSentMail] = useState(false);
  const [isSuccessSendEmail, setIsSuccessSendEmail] = useState(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const [formData, setFormData] = useState({
    "Help Type": "General Enquiry",
    Name: "",
    Email: "",
    "Contact Number": "",
    Message: "",
    Consent: false,
  });

  const [leasingFormData, setLeasingFormData] = useState({
    Name: "",
    Email: "",
    "Contact Number": "",
    "Existing Shop Name": "",
    "Trade/Merchandise": "",
    "Area Required": "",
    "M & E Requirements": "",
    Message: "",
    Consent: false,
  });

  const handleTextInputChange = (label, value) => {
    if (helpType === "Leasing Enquiry") {
      setLeasingFormData((prev) => ({ ...prev, [label]: value }));
    } else {
      setFormData((prev) => ({ ...prev, [label]: value }));
    }
  };

  const handleConsentChange = (e) => {
    if (helpType === "Leasing Enquiry") {
      setLeasingFormData((prev) => ({ ...prev, Consent: e.target.checked }));
    } else {
      setFormData((prev) => ({ ...prev, Consent: e.target.checked }));
    }
  };

  const handleSubmit = () => {
    const activeForm =
      helpType === "Leasing Enquiry" ? leasingFormData : formData;
    const requiredFields = Object.keys(activeForm).filter(
      (key) => key !== "Consent"
    );

    const missingFields = requiredFields.filter((field) => !activeForm[field]);

    if (missingFields.length > 0) {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
      return;
    }

    if (!activeForm.Consent) {
      alert("You must agree to the consent checkbox before submitting.");
      return;
    }

    // Required fields validation
    if (!activeForm.Name || !activeForm.Email || !activeForm.Message) {
      alert("Please fill in all required fields (Name, Email, and Message).");
      return;
    }

    setIsLoadingSendEmail(true);

    // Construct email subject
    const subject = `${helpType} from ${activeForm.Name}`;

    formData["Help Type"] = helpType;

    // Generate email body dynamically
    const body = Object.entries(activeForm)
      .filter(([key, value]) => value && key !== "Consent") // Exclude empty fields
      .map(([key, value]) => `${key}: ${value}`)
      .join("<br/>");

    sendEmail(getRecipient(helpType), subject, body).then((result) => {
      setIsSuccessSendEmail(result);
      setShowModalAfterSentMail(true);
      setIsLoadingSendEmail(false);
    });
    recaptcha.current.reset();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="flex flex-col lg:flex-row px-4 lg:px-[120px] py-10 lg:py-[97px] gap-[25px] lg:items-center"
      style={{ background: `url(${bgContactUs})`, backgroundSize: "cover" }}
    >
      <div className="flex flex-col flex-1 px-4">
        <TitleSection
          title="Contact Us"
          color="border-[#D05354]"
          className="mb-4 lg:mb-8"
        />
        <div className="text-2xl lg:text-5xl font-bold mb-6 lg:mb-[30px] leading-[130%] tracking-tighter">
          {extractTextFromTags(contactUsSupportingText, "h1") ??
            "We are always happy to assist you"}
        </div>
        <div className="flex flex-col lg:flex-row text-xs lg:text-xl gap-2 lg:gap-[80px]">
          <div className="flex flex-col flex-1 gap-2 lg:gap-4">
            <div className="font-bold">Email Address</div>
            <div>{contactUsEmail ?? "promotions@orchardgateway.sg"}</div>
          </div>
          <div className="flex flex-col flex-1 gap-2 lg:gap-4">
            <div className="font-bold">Number</div>
            <div>{contactUsPhone ?? "6513 4633"}</div>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 bg-white rounded-2xl border-[2px] border-[#20294C0A] p-8 md:p-10">
        {/* Help Type Dropdown */}
        <SelectInput
          label="What do you need help with?"
          value={helpType}
          onChange={(e) => {
            recaptcha.current.reset();
            setHelpType(e.target.value);
          }}
          options={[
            "General Enquiry",
            "Advertising & Event Space Enquiry",
            "Leasing Enquiry",
            "Season Parking Enquiry",
          ]}
        />

        {/* Form Fields */}
        {(helpType === "Leasing Enquiry"
          ? [
              "Name",
              "Email",
              "Contact Number",
              "Existing Shop Name",
              "Trade/Merchandise",
              "Area Required",
              "M & E Requirements",
              "Message",
            ]
          : ["Name", "Email", "Contact Number", "Message"]
        ).map((label, index) => (
          <div key={index} className="mt-2">
            <TextInput
              label={label}
              placeholder={
                label === "Message"
                  ? `Your ${label.toLowerCase()}`
                  : `Enter your ${label.toLowerCase()}`
              }
              lines={label === "Message" ? 4 : undefined}
              value={
                helpType === "Leasing Enquiry"
                  ? leasingFormData[label]
                  : formData[label]
              }
              onChange={(e) => handleTextInputChange(label, e.target.value)}
            />
          </div>
        ))}

        {/* Captcha */}
        <div className="mt-4">
          <ReCAPTCHA ref={recaptcha} sitekey={captchaConstant.sitekey} />
        </div>

        {/* Consent Checkbox */}
        <div className="mt-4">
          <FormConsentCheckbox
            checked={
              helpType === "Leasing Enquiry"
                ? leasingFormData.Consent
                : formData.Consent
            }
            onChange={handleConsentChange}
          />
        </div>

        {/* Submit Button */}
        <div className="mt-4">
          <FormSubmitButton onClick={handleSubmit} fullWidth />
        </div>
      </div>

      {showModalAfterSentMail && (
        <ModalMailSent
          isSuccess={isSuccessSendEmail}
          onClose={() => setShowModalAfterSentMail(false)}
        />
      )}
      {isLoadingSendEmail && (
        <div className="fixed inset-0 z-40 bg-white bg-opacity-50 flex items-center justify-center">
          <div className="w-[200px] h-[200px]">
            <Lottie options={defaultOptions} />
          </div>
        </div>
      )}
    </div>
  );
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const Footer = ({ socialMedia, addressLocation, privacyPolicy, urlTnc }) => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTnc, setShowTnc] = useState(false);

  return (
    <div>
      {/* Mobile View */}
      <div className="flex flex-col bg-[#20294C] px-20 py-8 gap-y-8 items-center relative lg:hidden">
        <FooterLogo className="mt-8" />
        <div className="flex flex-row gap-x-2">
          <a href={socialMedia?.facebook} target="_blank">
            <img src={fbIcon} />
          </a>
          <a href={socialMedia?.instagram} target="_blank">
            <img src={igIcon} />
          </a>
          <a href={socialMedia?.tiktok} target="_blank">
            <img src={tiktokIcon} />
          </a>
        </div>
        <div className="flex flex-col items-center justify-center gap-y-2">
          <div className="flex flex-row items-center gap-x-2">
            <div class="w-2 h-2 bg-white/40 rounded-full" />
            <span className="text-white/40 font-bold">MENU</span>
          </div>{" "}
          <a href="/">
            <span className="text-white text-xs cursor-pointer">Home</span>
          </a>
          <a href="/about-us">
            <span className="text-white text-xs cursor-pointer">About Us</span>
          </a>
          <a href="/about-us?scrollTo=direction&section=carpark">
            <span className="text-white text-xs cursor-pointer">Carpark</span>
          </a>
          <a href="/contact-us">
            <span className="text-white text-xs cursor-pointer">
              Contact Us
            </span>
          </a>
        </div>

        <div className="flex flex-col items-center justify-center gap-y-2">
          <div className="flex flex-row items-center gap-x-2">
            <div class="w-2 h-2 bg-white/40 rounded-full" />
            <span className="text-white/40 font-bold">LOCATION</span>
          </div>
          <span className="text-white text-xs text-center">
            {addressLocation ?? "277 Orchard Road Singapore 238858"}
          </span>
        </div>
        <hr class="border border-[#3477B9] w-[80px] " />

        <span
          className="text-white/40 text-xs underline cursor-pointer"
          onClick={() => setShowPrivacyPolicy(true)}
        >
          Privacy Policy
        </span>

        <span className="text-white text-xs text-center">
          © 2025 — orchardgateway All Rights Reserved
        </span>

        <div className="absolute top-[-20px]">
          <img
            src={backToTop}
            className="w-12 h-12 cursor-pointer"
            onClick={scrollToTop}
          />
        </div>
      </div>
      {/* Desktop View */}
      <div className="hidden lg:flex flex-col bg-[#20294C] h-[500px] p-20 gap-y-20 relative">
        <div className="flex flex-row">
          <div className="flex flex-col w-2/3">
            <FooterLogo />
          </div>
          <div className="flex flex-row gap-x-20 w-1/3">
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-x-2 mb-4">
                <div class="w-2 h-2 bg-white/40 rounded-full" />
                <span className="text-white/40 font-bold">MENU</span>
              </div>
              <a href="/">
                <span className="text-white cursor-pointer">Home</span>
              </a>
              <a href="/about-us">
                <span className="text-white cursor-pointer">About Us</span>
              </a>
              <a href="/about-us?scrollTo=direction&section=carpark">
                <span className="text-white cursor-pointer">Carpark</span>
              </a>
              <a href="/contact-us">
                <span className="text-white cursor-pointer">Contact Us</span>
              </a>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row items-center gap-x-2 mb-4">
                <div class="w-2 h-2 bg-white/40 rounded-full" />
                <span className="text-white/40 font-bold">LOCATION</span>
              </div>
              {addressLocation?.split(/\r\n/).map((data) => {
                return <span className="text-white">{data}</span>;
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end">
          <div className="flex flex-row gap-x-6 w-2/3">
            <a href={socialMedia?.facebook} target="_blank">
              <img src={fbIcon} />
            </a>
            <a href={socialMedia?.instagram} target="_blank">
              <img src={igIcon} />
            </a>
            <a href={socialMedia?.tiktok} target="_blank">
              <img src={tiktokIcon} />
            </a>
          </div>
          <div>
            <hr class="border border-[#3477B9] w-[200px] max-w-[200px]" />
          </div>
        </div>
        <div className="flex flex-row justify-between text-xs text-white/40">
          <div className="flex flex-col">
            <span>© 2025 — orchardgateway</span>
            <span>All Rights Reserved</span>
          </div>
          <span
            className="underline cursor-pointer"
            onClick={() => setShowPrivacyPolicy(true)}
          >
            Privacy Policy
          </span>
        </div>

        <div className="absolute top-[-30px] left-1/2">
          <img
            src={backToTop}
            className="w-20 h-20 cursor-pointer"
            onClick={scrollToTop}
          />
        </div>
      </div>

      {showPrivacyPolicy && privacyPolicy && (
        <PrivacyPolicy
          privacyPolicy={privacyPolicy}
          onClose={() => setShowPrivacyPolicy(false)}
          onOpenTnc={() => setShowTnc(true)}
        />
      )}
      {showTnc && urlTnc && (
        <TermsConditions content={urlTnc} onClose={() => setShowTnc(false)} />
      )}
    </div>
  );
};

const Layout = observer(
  ({
    children,
    headerImg,
    headerUrl,
    headerUrlMobile,
    title,
    headerHeight,
  }) => {
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);
    const [socialMediaList, setSocialMediaList] = useState(null);
    const [contactUsEmail, setContactUsEmail] = useState(null);
    const [contactUsPhone, setContactUsPhone] = useState(null);
    const [contactUsSupportingText, setContactUsSupportingText] =
      useState(null);
    const [addressLocation, setAddressLocation] = useState(null);
    const [socialMedia, setSocialMedia] = useState(null);
    const [privacyPolicy, setPrivacyPolicy] = useState(null);
    const [urlTnc, setUrlTnc] = useState(null);
    const mapStore = useContext(AdsignMapContext);

    useEffect(() => {
      if (mapStore && mapStore.information && mapStore.information.length > 0) {
        const allPagesContent = mapStore.information
          .filter((info) => {
            return info.categoryName.toLowerCase().includes("all pages");
          })
          .sort((a, b) => a.name.localeCompare(b.name));

        const privacyPolicy = mapStore.information.filter((info) => {
          return info.name.toLowerCase().includes("data privacy policy");
        });
        if (privacyPolicy) {
          setUrlTnc(getInformationAsset(privacyPolicy?.[0]?.files?.[0]?.logo));
        }

        const contactUsData = allPagesContent.filter((data) => {
          return data.name.toLowerCase().includes("contact us");
        });

        const footerData = allPagesContent.filter((data) => {
          return data.name.toLowerCase().includes("footer");
        });

        if (contactUsData) {
          contactUsData.map((data) => {
            if (data.name.toLowerCase().includes("email address")) {
              setContactUsEmail(data.description);
            } else if (data.name.toLowerCase().includes("phone number")) {
              setContactUsPhone(data.description);
            } else if (data.name.toLowerCase().includes("supporting text")) {
              setContactUsSupportingText(data.description);
            }
          });
        }
        if (footerData) {
          footerData.map((data) => {
            if (data.name.toLowerCase().includes("address")) {
              setAddressLocation(data.description);
            } else if (data.name.toLowerCase().includes("social media")) {
              const regex = /<li>([^:]+):\s*([^<]*)<\/li>/g;
              let match;
              const socialMediaObj = {
                facebook: "",
                instagram: "",
                tiktok: "",
              };

              while ((match = regex.exec(data.description)) !== null) {
                const platform = match[1].trim().toLowerCase();
                let url = match[2].trim();
                if (platform === "facebook") {
                  socialMediaObj.facebook = url;
                } else if (platform === "instagram") {
                  socialMediaObj.instagram = url;
                } else if (platform === "tiktok") {
                  socialMediaObj.tiktok = url || "";
                }
              }
              setSocialMedia(socialMediaObj);
            } else if (data.name.toLowerCase().includes("privacy policy")) {
              setPrivacyPolicy(data.description);
            }
          });
        }
      }
    }, [mapStore, mapStore.information]);

    return (
      <div className="w-full h-full flex flex-col overflow-auto">
        <div className="relative">
          <Header />
          <div className="relative w-full">
            {headerImg ? (
              <ImageLoader
                src={require(`../../assets/${headerImg}.png`)}
                className={`w-full ${
                  headerHeight ?? "h-[250px] md:96 object-cover"
                } `}
              />
            ) : (
              <>
                <ImageLoader
                  src={headerUrl}
                  className={`w-full ${
                    headerHeight ?? "h-[250px] md:96"
                  } object-cover ${headerUrlMobile && "hidden md:flex"}`}
                />
                {/* in case there is mobile img */}
                {headerUrlMobile && (
                  <ImageLoader
                    src={headerUrlMobile}
                    className={`w-full ${
                      headerHeight ?? "h-[250px] md:96"
                    } object-cover flex md:hidden`}
                  />
                )}
              </>
            )}

            <div
              className={`absolute inset-0 bg-gradient-to-b from-[black] to-black/15 ${
                useMatch("/") && "hidden"
              }`}
            />
          </div>
          {title && (
            <div>
              <div className="block md:hidden absolute right-0 left-0 top-1/2">
                <h1 className="text-white text-center text-xl md:text-2xl font-bold font-[Oplicio]">
                  {title}
                </h1>
              </div>
              <div className="hidden md:block absolute px-12 pb-8 bottom-10 left-0">
                <h1 className="text-white md:text-4xl lg:text-6xl font-bold font-[Oplicio]">
                  {title}
                </h1>
              </div>
            </div>
          )}
        </div>

        <div className="h-full w-full overflow-hidden">{children}</div>
        {/* footer */}
        {useMatch("/about-us") ? (
          <AboutUsContactUs
            contactUsEmail={contactUsEmail}
            contactUsPhone={contactUsPhone}
            contactUsSupportingText={contactUsSupportingText}
          />
        ) : (
          <DefaultContactUs
            contactUsEmail={contactUsEmail}
            contactUsPhone={contactUsPhone}
            contactUsSupportingText={contactUsSupportingText}
          />
        )}
        <Footer
          socialMedia={socialMedia}
          addressLocation={addressLocation}
          privacyPolicy={privacyPolicy}
          urlTnc={urlTnc}
        />
      </div>
    );
  }
);

export default Layout;
