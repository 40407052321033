import React, { useState, useRef, useEffect } from "react";

const VideoPlayer = ({ src, className }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isInitialInit, setIsInitialInit] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const togglePlayPause = (e) => {
    // Prevent default behavior that might trigger fullscreen
    e.preventDefault();

    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        // Use promise to handle autoplay restrictions
        const playPromise = videoRef.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Playback started successfully
            })
            .catch((error) => {
              // Auto-play was prevented
              console.log("Playback prevented:", error);
            });
        }
      }
      setIsPlaying(!isPlaying);
    }
  };

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      if (isInitialInit) {
        setIsInitialInit(false);

        // Add playsInline attribute programmatically
        video.playsInline = true;

        // Try to play with promise handling
        const playPromise = video.play();
        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setIsPlaying(true);
            })
            .catch(() => {
              // Autoplay was blocked
              setIsPlaying(false);
            });
        }
      }

      // Add event listeners to handle fullscreen changes
      const handleFullscreenChange = () => {
        // Exit fullscreen if it was entered
        if (
          document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement
        ) {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          }
        }
      };

      video.addEventListener("webkitbeginfullscreen", handleFullscreenChange);
      video.addEventListener("webkitendfullscreen", handleFullscreenChange);

      return () => {
        video.removeEventListener(
          "webkitbeginfullscreen",
          handleFullscreenChange
        );
        video.removeEventListener(
          "webkitendfullscreen",
          handleFullscreenChange
        );
      };
    }
  }, [isInitialInit]);

  return (
    <div className="relative w-full max-full mx-auto h-full">
      <video
        ref={videoRef}
        className={className}
        onClick={togglePlayPause}
        src={src}
        muted={isMuted}
        loop
        playsInline
        webkit-playsinline="true"
        controls={false}
      >
        Your browser does not support the video tag.
      </video>

      <div
        className="absolute inset-0 flex items-center justify-center"
        onClick={togglePlayPause}
      >
        {!isPlaying && (
          <div className="bg-black bg-opacity-50 rounded-full p-4 text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polygon points="5 3 19 12 5 21 5 3"></polygon>
            </svg>
          </div>
        )}
      </div>

      <div>
        <div
          className="cursor-pointer absolute top-2 right-2"
          onClick={(e) => {
            e.stopPropagation();
            setIsMuted(!isMuted);
          }}
          aria-label={isMuted ? "Unmute" : "Mute"}
          role="button"
        >
          {/* Your SVG icons remain the same */}
          {isMuted ? (
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              color="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 5L6 9H2V15H6L11 19V5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23 9L17 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17 9L23 15"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              color="white"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 5L6 9H2V15H6L11 19V5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.54 8.46C16.4774 9.39764 17.0039 10.6692 17.0039 11.995C17.0039 13.3208 16.4774 14.5924 15.54 15.53"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.07 5.93C20.9447 7.80528 21.9979 10.3447 21.9979 13C21.9979 15.6553 20.9447 18.1947 19.07 20.07"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
