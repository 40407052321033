import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import Layout from "../../components/Layout";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import FormSubmitButton from "../../components/FormSubmitButton";
import FormConsentCheckbox from "../../components/FormConsentCheckbox";
import FileInput from "../../components/FileInput";
import { sendEmail, verifyCaptcha } from "../../axiosActions";
import ReCAPTCHA from "react-google-recaptcha";
import { captchaConstant, getRecipient } from "../../constants/appConstant";
import * as animationData from "../../assets/lottie/loading.json";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";
import ModalMailSent from "../../components/ModalMailSent";
import Lottie from "react-lottie";
import { Helmet } from "react-helmet-async";

const GeneralForm = ({ onSubmit, captchaRef, onShowTermsConditionsModal }) => {
  const [formData, setFormData] = useState({
    "Help Type": "General Enquiry",
    Name: "",
    Email: "",
    "Contact Number": "",
    Message: "",
    Consent: false,
  });

  const handleTextInputChange = (label, value) => {
    setFormData((prev) => ({
      ...prev,
      [label]: value,
    }));
  };

  const handleConsentChange = (e) => {
    setFormData((prev) => ({ ...prev, Consent: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const response = await verifyCaptcha(captchaValue);
      if (!response) {
        return;
      }
    }

    // Check if any required field is empty
    const requiredFields = ["Name", "Email", "Contact Number", "Message"];
    const missingFields = requiredFields.filter((field) => !formData[field]);
    if (missingFields.length > 0) {
      alert(`Please fill in all required fields: ${missingFields.join(", ")}`);
      return;
    }

    if (!formData.Consent) {
      alert("You must agree to the consent checkbox before submitting.");
      return;
    }

    await onSubmit(formData);
  };

  return (
    <>
      <div className="mt-4">
        <TextInput
          label={"Name"}
          placeholder={"Enter your Name"}
          onChange={(e) => handleTextInputChange("Name", e.target.value)}
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-x-10 mt-4">
        <div className="flex-1">
          <TextInput
            label={"Email"}
            placeholder={"Enter your Email Address"}
            onChange={(e) => handleTextInputChange("Email", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <TextInput
            label={"Contact Number"}
            placeholder={"Enter your contact number"}
            onChange={(e) =>
              handleTextInputChange("Contact Number", e.target.value)
            }
          />
        </div>
      </div>

      <div className="mt-4">
        <TextInput
          label={"Message"}
          placeholder={"Your message"}
          onChange={(e) => handleTextInputChange("Message", e.target.value)}
          lines={4}
        />
      </div>

      <div className="mt-8">
        <ReCAPTCHA ref={captchaRef} sitekey={captchaConstant.sitekey} />
      </div>

      <div className="mt-8">
        <FormConsentCheckbox onChange={handleConsentChange} />
      </div>

      <div className="mt-4">
        <FormSubmitButton onClick={handleSubmit} />
      </div>
    </>
  );
};

const LeasingForm = ({ onSubmit, captchaRef, onShowTermsConditionsModal }) => {
  const [formData, setFormData] = useState({
    "Help Type": "Leasing Enquiry",
    Name: "",
    Email: "",
    "Contact Number": "",
    "Existing Shop Name": "",
    "Trade/Merchandise": "",
    "Area Required": "",
    "M & E Requirements": "",
    Message: "",
    Consent: false,
  });

  const handleTextInputChange = (label, value) => {
    setFormData((prev) => ({
      ...prev,
      [label]: value,
    }));
  };

  const handleConsentChange = (e) => {
    setFormData((prev) => ({ ...prev, Consent: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaValue = captchaRef.current.getValue();

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const response = await verifyCaptcha(captchaValue);
      if (!response) {
        return;
      }
    }

    // Check if any required field is empty
    const requiredFields = [
      "Name",
      "Email",
      "Contact Number",
      "Trade/Merchandise",
      "Area Required",
      "Message",
    ];
    const missingFields = requiredFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      alert(`Please fill in all required fields: ${missingFields.join(", ")}`);
      return;
    }

    if (!formData.Consent) {
      alert("You must agree to the consent checkbox before submitting.");
      return;
    }

    await onSubmit(formData);
  };

  return (
    <>
      <div className="mt-4">
        <TextInput
          label={"Name"}
          placeholder={"Enter your Name"}
          onChange={(e) => handleTextInputChange("Name", e.target.value)}
        />
      </div>

      <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-x-10 mt-4">
        <div className="flex-1">
          <TextInput
            label={"Email"}
            placeholder={"Enter your Email Address"}
            onChange={(e) => handleTextInputChange("Email", e.target.value)}
          />
        </div>
        <div className="flex-1">
          <TextInput
            label={"Contact Number"}
            placeholder={"Enter your contact number"}
            onChange={(e) =>
              handleTextInputChange("Contact Number", e.target.value)
            }
          />
        </div>
      </div>

      <div className="mt-4">
        <TextInput
          label={"Existing Shop Name (If Any)"}
          placeholder={"Enter your shop name"}
          onChange={(e) =>
            handleTextInputChange("Existing Shop Name", e.target.value)
          }
        />
      </div>

      <div className="mt-4">
        <TextInput
          label={"Trade/Merchandise"}
          placeholder={"Trade/Merchandise"}
          onChange={(e) =>
            handleTextInputChange("Trade/Merchandise", e.target.value)
          }
        />
      </div>

      <div className="mt-4">
        <TextInput
          label={"Area Required (sq ft)"}
          placeholder={"Enter your Area Required (sq ft)"}
          onChange={(e) =>
            handleTextInputChange("Area Required", e.target.value)
          }
        />
      </div>

      <div className="mt-4">
        <TextInput
          label={"M & E Requirements (If Any)"}
          placeholder={"Enter your M & E Requirements"}
          onChange={(e) =>
            handleTextInputChange("M & E Requirements", e.target.value)
          }
        />
      </div>

      <div className="mt-4">
        <TextInput
          label={"Message"}
          placeholder={"Your message"}
          onChange={(e) => handleTextInputChange("Message", e.target.value)}
          lines={4}
        />
      </div>

      {/* <div className="mt-4">
        <FileInput onFileChange={(file) => {}} />
      </div> */}

      <div className="mt-8">
        <ReCAPTCHA ref={captchaRef} sitekey={captchaConstant.sitekey} />
      </div>

      <div className="mt-8">
        <FormConsentCheckbox onChange={handleConsentChange} />
      </div>

      <div className="mt-4">
        <FormSubmitButton onClick={handleSubmit} />
      </div>
    </>
  );
};

const ContactUs = observer(() => {
  const [helpType, setHelpType] = useState("General Enquiry");
  const [contentTermsConditions, setContentTermsConditions] = useState(null);
  const [showModalAfterSentMail, setShowModalAfterSentMail] = useState(false);
  const [isSuccessSendEmail, setIsSuccessSendEmail] = useState(false);
  const [isLoadingSendEmail, setIsLoadingSendEmail] = useState(false);
  const recaptcha = useRef();

  useEffect(() => {
    logAnalyticsEvent("destination", {
      page_title: `contact_us`,
    });
  }, []);

  const handleSubmit = (formData) => {
    // Required fields validation
    if (!formData.Name || !formData.Email || !formData.Message) {
      alert("Please fill in all required fields (Name, Email, and Message).");
      return;
    }
    setIsLoadingSendEmail(true);
    // Construct email subject
    const subject = `${helpType} from ${formData.Name}`;

    formData["Help Type"] = helpType;

    // Generate email body dynamically
    const body = Object.entries(formData)
      .filter(([key, value]) => value && key !== "Consent") // Exclude empty fields
      .map(([key, value]) => `${key}: ${value}`)
      .join("<br/>");

    sendEmail(getRecipient(helpType), subject, body).then((result) => {
      setIsSuccessSendEmail(result);
      setShowModalAfterSentMail(true);
      setIsLoadingSendEmail(false);
    });
    recaptcha.current.reset();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Get in touch with us, We're here to assist you."
        />
      </Helmet>
      <Layout
        headerImg={"contact-us"}
        title={"Contact Us"}
        headerHeight={"h-[228px] md:h-[416px]"}
      >
        <div className="h-full">
          <div className="flex p-4 lg:p-[120px] w-full flex-col">
            <p className="text-2x md:text-4xl lg:text-6xl">
              <p>
                <span className="font-bold" style={{ color: "#3477B9" }}>
                  Get in touch{" "}
                </span>
                <span>with us. </span>
              </p>
              <p>We're here to assist you.</p>
            </p>

            <div className="mt-10">
              <SelectInput
                label={"What do you need help with?"}
                value={helpType}
                onChange={(e) => {
                  recaptcha.current.reset();
                  setHelpType(e.target.value);
                }}
                options={[
                  "General Enquiry",
                  "Advertising & Event Space Enquiry",
                  "Leasing Enquiry",
                  "Season Parking Enquiry",
                ]}
              />
            </div>
            {helpType === "Leasing Enquiry" ? (
              <LeasingForm onSubmit={handleSubmit} captchaRef={recaptcha} />
            ) : (
              <GeneralForm
                onSubmit={handleSubmit}
                captchaRef={recaptcha}
                linkTnC={contentTermsConditions}
              />
            )}
          </div>
          {showModalAfterSentMail && (
            <ModalMailSent
              isSuccess={isSuccessSendEmail}
              onClose={() => setShowModalAfterSentMail(false)}
            />
          )}
          {isLoadingSendEmail && (
            <div className="fixed inset-0 z-40 bg-white bg-opacity-50 flex items-center justify-center">
              <div className="w-[200px] h-[200px]">
                <Lottie options={defaultOptions} />
              </div>
            </div>
          )}
        </div>
      </Layout>
    </>
  );
});

export default ContactUs;
