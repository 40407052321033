import React, { useEffect, useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import Layout from "../../components/Layout";
import homeBackground1 from "../../assets/homebackground1.png";
import arrowRight from "../../assets/icons/arrow-right.svg";
import doubleQuotation from "../../assets/double-quotation.png";
import ImageRowSlider from "../../components/Sliders/imageRowSlider";
import ContentSlider from "../../components/Sliders/contentSlider";
import { AdsignMapContext } from "../../adsignMapProvider";
import { extractTextFromTags, getInformationAsset } from "../../helper";
import VideoPlayer from "../../components/Video";
import TitleSection from "../../components/TitleSection";
import ImageLoader from "../../components/ImageLoader";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";
import { Helmet } from "react-helmet-async";

const ExploreMobilePlaceholder = () => {
  return (
    <div className="flex flex-col p-4 gap-y-4 w-full justify-center md:hidden">
      <ImageLoader className="h-[250px] w-full rounded-xl" />
      <ImageLoader className="h-[250px] w-full rounded-xl" />
    </div>
  );
};

const ExploreDesktopPlaceholder = () => {
  return (
    <div className="hidden md:flex flex-row w-full justify-center gap-x-2 xl:gap-x-6 mt-16 pb-[168px] md:px-4 xl:px-0">
      <div className="flex flex-col w-[350px] xl:w-[430px] gap-y-4">
        {[0, 1].map(() => (
          <ImageLoader className="size-full rounded-xl" />
        ))}
      </div>
      <div className="flex flex-col w-[350px] xl:w-[430px] gap-y-4">
        {[2, 3].map(() => (
          <ImageLoader className="size-full  rounded-xl" />
        ))}
      </div>
      <div className="relative">
        <ImageLoader className="h-[450px] xl:h-[600px] w-[300px] xl:w-[380px]  rounded-xl" />
      </div>
    </div>
  );
};

const Home = observer(() => {
  const mapStore = useContext(AdsignMapContext);
  const [homePageImg, setHomePageImg] = useState(null);
  const [homePageMobileImg, setHomePageMobileImg] = useState(null);

  const [exploreOgwData, setExploreOgwData] = useState(null);
  const [exploreOgwDataMobile, setExploreOgwDataMobile] = useState(null);

  const [homePageAboutUsContent, setHomePageAboutUsContent] = useState(null);
  const [homePageWhatsOnContent, setHomePageWhatsOnContent] = useState(null);
  const [dataPrivacyContent, setDataPrivacyContent] = useState(null);
  const [homePageDirectoryContent, setHomePageDirectoryContent] =
    useState(null);

  const [whatsOnPageContent, setWhatsOnPageContent] = useState();

  useEffect(() => {
    logAnalyticsEvent("destination", {
      page_title: `home`,
    });
  }, []);

  useEffect(() => {
    if (mapStore && mapStore.information && mapStore.information.length > 0) {
      const homePageData = mapStore.information
        .filter((info) => {
          return info.name.includes("Homepage");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      const privacyPolicy = mapStore.information
        .filter((info) => {
          return info.name.toLowerCase().includes("data privacy policy");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      const whatsOnPageData = mapStore.information
        .filter((info) => {
          return info.name.toLowerCase().includes("what's on page");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      const landingPageData = homePageData.filter((data) => {
        return data.name.toLowerCase().includes("landing page media");
      });

      const aboutUsContent = homePageData.filter((data) => {
        return data.name.toLowerCase().includes("about us");
      });

      const whatsOnContent = homePageData.filter((data) => {
        return data.name.toLowerCase().includes("what's on");
      });

      const directoryContent = homePageData.filter((data) => {
        return data.name.toLowerCase().includes("directory");
      });

      if (landingPageData) {
        const mobile = landingPageData.filter((data) =>
          data.name.toLowerCase().includes("mobile")
        );
        const desktop = landingPageData.filter((data) =>
          data.name.toLowerCase().includes("website")
        );

        setHomePageImg(desktop[0].files[0].logo);
        setHomePageMobileImg(mobile[0].files[0].logo);
      }

      if (aboutUsContent) {
        setHomePageAboutUsContent(aboutUsContent[0]);
      }

      if (whatsOnContent) {
        setHomePageWhatsOnContent(whatsOnContent[0]);
      }

      if (directoryContent) {
        setHomePageDirectoryContent(directoryContent[0]);
      }

      if (privacyPolicy) {
        setDataPrivacyContent(privacyPolicy[0]);
      }
    }
  }, [mapStore, mapStore.information]);

  useEffect(() => {
    if (mapStore && mapStore.information && mapStore.information.length > 0) {
      const exploreData = mapStore.information
        .filter((info) => {
          return info.name
            .toLowerCase()
            .includes("homepage - explore orchardgateway");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      const exploreWhatsOn = exploreData.filter((data) => {
        return data.name.toLowerCase().includes("whats on");
      });
      const exploreAboutUs = exploreData.filter((data) => {
        return data.name.toLowerCase().includes("about us");
      });
      const exploreStoreDirectory = exploreData.filter((data) => {
        return data.name.toLowerCase().includes("store directory");
      });
      const exploreFloorGuide = exploreData.filter((data) => {
        return data.name.toLowerCase().includes("floorguide");
      });
      const exploreInstagram = exploreData.filter((data) => {
        return data.name.toLowerCase().includes("instagram");
      });
      let instagramData;
      if (mapStore && mapStore.medias && mapStore.medias.length > 0) {
        instagramData = mapStore.medias.filter((data) => {
          return data?.url?.includes(exploreInstagram[0].description);
        });
      }
      console.log(exploreInstagram, "exploreInstagram");
      setExploreOgwDataMobile([
        {
          name: "Store Directory",
          imgUrl: exploreStoreDirectory[0].files[0].logo,
          url: "/directory",
        },
        {
          name: "What`s On",
          imgUrl: exploreWhatsOn[0].files[0].logo,
          url: "/whats-on",
        },
        {
          name: "About Us",
          imgUrl: exploreAboutUs[0].files[0].logo,
          url: "/about-us",
        },
        {
          name: "Floor Guide",
          imgUrl: exploreFloorGuide[0].files[0].logo,
          url: "/floorguide",
        },
        {
          name: "@orchardgatewaysg",
          imgUrl:
            exploreInstagram &&
            exploreInstagram.length > 0 &&
            exploreInstagram[0]?.files[0]?.logo,
          url:
            exploreInstagram &&
            exploreInstagram.length > 0 &&
            exploreInstagram[0]?.files[0]?.logo,
          fileType:
            exploreInstagram &&
            exploreInstagram.length > 0 &&
            exploreInstagram[0]?.files[0]?.fileType,
        },
      ]);

      setExploreOgwData([
        {
          name: "What`s On",
          imgUrl: exploreWhatsOn[0].files[0].logo,
          url: "/whats-on",
        },
        {
          name: "About Us",
          imgUrl: exploreAboutUs[0].files[0].logo,
          url: "/about-us",
        },
        {
          name: "Store Directory",
          imgUrl: exploreStoreDirectory[0].files[0].logo,
          url: "/directory",
        },
        {
          name: "Floor Guide",
          imgUrl: exploreFloorGuide[0].files[0].logo,
          url: "/floorguide",
        },
        {
          name: "@orchardgatewaysg",
          imgUrl:
            exploreInstagram &&
            exploreInstagram.length > 0 &&
            exploreInstagram[0]?.files[0]?.logo,
          url:
            exploreInstagram &&
            exploreInstagram.length > 0 &&
            exploreInstagram[0]?.files[0]?.logo,
          fileType:
            exploreInstagram &&
            exploreInstagram.length > 0 &&
            exploreInstagram[0]?.files[0]?.fileType,
        },
      ]);
    }
  }, [mapStore, mapStore.information]);

  useEffect(() => {
    if (mapStore && mapStore.medias && mapStore.medias.length > 0) {
      const contentData = mapStore.medias
        .filter((data) => {
          return data.categoryName
            .toLowerCase()
            .includes("events & promotions");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      if (contentData) {
        setWhatsOnPageContent(contentData);
      }
    }
  }, [mapStore, mapStore.medias]);

  const exploreCard = (name, imgUrl, link) => (
    <div className="relative h-full w-full ">
      <a href={link}>
        <div className="absolute bottom-0 z-10 flex flex-row justify-between items-center rounded-bl-xl bg-[#00000066] backdrop-blur-lg w-[90%] md:w-max">
          <div className="py-2 pl-2 pr-8 xl:py-4 xl:pl-6 xl:pr-16 rounded rounded-bl-xl ">
            <span className="text-white font-bold text-base text-base xl:text-[20px]">
              {name}
            </span>
          </div>
          <div className="p-2 xl:p-4 bg-white bg-opacity-20">
            <svg
              width="40"
              height="30"
              viewBox="0 0 28 28"
              fill="none"
              className="w-[20px] xl:w-[40px]"
            >
              <path
                d="M14.2007 1L23 9.99905M23 9.99905L14.2007 19M23 9.99905L1 10.0009"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
                strokeWidth={2}
              />
            </svg>
          </div>
        </div>
      </a>

      <ImageLoader
        src={getInformationAsset(imgUrl)}
        className="h-full w-full object-cover object-center rounded rounded-xl"
      />
    </div>
  );

  const explore = () => {
    return (
      <div
        className="flex flex-col bg-none sm:bg-[image:var(--bg-image)]"
        style={{
          "--bg-image": `url(${homeBackground1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex flex-row w-full justify-center items-center pt-8 md:pt-[168px]">
          <ImageLoader
            src={require("../../assets/explore-orchard.png")}
            className="px-[18px] h-[30px] md:px-0 md:h-[48px] xl:h-[60px] object-contain"
            alt="orchard gateway logo"
          />
        </div>

        {/* mobile view */}
        {exploreOgwDataMobile ? (
          <div className="flex flex-col p-4 gap-y-4 w-full justify-center md:hidden">
            {exploreOgwDataMobile &&
              exploreOgwDataMobile?.map((data) => {
                if (data.name !== "@orchardgatewaysg")
                  return exploreCard(data.name, data.imgUrl, data.url);
              })}

            <div className="relative">
              <a href={"/whats-on?scrollTo=instagramContent"}>
                <div className="absolute bottom-0 flex flex-row items-center rounded-bl-xl bg-[#00000066] backdrop-blur-lg z-50">
                  <div className="py-4 pl-6 pr-14 ">
                    <span className="text-white font-bold text-xl">
                      {exploreOgwDataMobile &&
                        exploreOgwDataMobile.length > 4 &&
                        exploreOgwDataMobile?.[4]?.name}
                    </span>
                  </div>
                  <div className="p-4 bg-white bg-opacity-20">
                    <svg width="40" height="30" viewBox="0 0 28 28" fill="none">
                      <path
                        d="M14.2007 1L23 9.99905M23 9.99905L14.2007 19M23 9.99905L1 10.0009"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </div>
              </a>

              {exploreOgwDataMobile &&
              exploreOgwDataMobile.length > 4 &&
              exploreOgwDataMobile?.[4]?.fileType === "image/png" ? (
                <ImageLoader
                  src={getInformationAsset(exploreOgwDataMobile?.[4]?.imgUrl)}
                  className="h-full w-full md:w-[380px] object-coverfa rounded-xl"
                />
              ) : exploreOgwDataMobile?.[4].fileType === "video/mp4" ? (
                <VideoPlayer
                  className="h-full w-full md:w-[380px] object-cover rounded-xl"
                  src={getInformationAsset(exploreOgwDataMobile?.[4]?.imgUrl)}
                />
              ) : (
                <div className="h-full w-full md:w-[380px] bg-black object-cover rounded-xl"></div>
              )}
            </div>
          </div>
        ) : (
          <ExploreMobilePlaceholder />
        )}

        {/* dekstop view */}
        {exploreOgwData ? (
          <div className="hidden md:flex flex-row w-full justify-center gap-x-2 xl:gap-x-6 mt-16 pb-[168px] md:px-4 xl:px-0">
            <div className="flex flex-col w-[350px] xl:w-[430px] gap-y-4">
              {exploreOgwData &&
                [0, 1]?.map((index) =>
                  exploreCard(
                    exploreOgwData?.[index].name,
                    exploreOgwData?.[index].imgUrl,
                    exploreOgwData?.[index].url
                  )
                )}
            </div>
            <div className="flex flex-col w-[350px] xl:w-[430px] gap-y-4">
              {exploreOgwData &&
                [2, 3]?.map((index) =>
                  exploreCard(
                    exploreOgwData?.[index].name,
                    exploreOgwData?.[index].imgUrl,
                    exploreOgwData?.[index].url
                  )
                )}
            </div>
            <div className="relative">
              <a href={"/whats-on?scrollTo=instagramContent"}>
                <div className="absolute bottom-0 flex flex-row items-center rounded-bl-xl bg-[#00000066] backdrop-blur-lg z-50 ">
                  <div className="py-2 pl-2 pr-4 xl:py-4 xl:pl-6 xl:pr-14">
                    <span className="text-white font-bold text-base xl:text-xl">
                      {exploreOgwData &&
                        exploreOgwData.length > 4 &&
                        exploreOgwData?.[4]?.name}
                    </span>
                  </div>
                  <div className="p-2 xl:p-4 bg-white bg-opacity-20">
                    <svg
                      width="40"
                      height="30"
                      viewBox="0 0 28 28"
                      fill="none"
                      className="w-[20px] xl:w-[40px]"
                    >
                      <path
                        d="M14.2007 1L23 9.99905M23 9.99905L14.2007 19M23 9.99905L1 10.0009"
                        stroke="white"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        strokeWidth={2}
                      />
                    </svg>
                  </div>
                </div>
              </a>

              {exploreOgwData &&
              exploreOgwData.length > 4 &&
              exploreOgwData?.[4]?.fileType === "image/png" ? (
                <ImageLoader
                  src={getInformationAsset(exploreOgwData?.[4]?.imgUrl)}
                  className="h-full w-[300px] xl:w-[380px] object-cover rounded rounded-xl"
                />
              ) : exploreOgwData?.[4].fileType === "video/mp4" ? (
                <VideoPlayer
                  className="h-full w-[300px] xl:w-[380px] object-cover rounded rounded-xl"
                  src={getInformationAsset(exploreOgwData?.[4]?.imgUrl)}
                />
              ) : (
                <div className="h-full w-[300px] xl:w-[380px] bg-black object-cover rounded rounded-xl"></div>
              )}
            </div>
          </div>
        ) : (
          <ExploreDesktopPlaceholder />
        )}
      </div>
    );
  };

  const aboutUs = () => {
    return (
      <div>
        {/* mobile view */}
        <div className="flex md:hidden flex-col items-center bg-[#FAFBFF] w-full px-4 md:px-6 pt-12 pb-6 ">
          <div className="relative">
            <div className="flex flex-col relative z-10 gap-y-4 md:gap-y-6">
              <TitleSection title={"ABOUT US"} color={"border-[#408ECF]"} />
              <span className="text-2xl text-[#1F284E] font-[Oplicio] font-bold mb-4">
                {homePageAboutUsContent?.description
                  ? extractTextFromTags(
                      homePageAboutUsContent.description,
                      "h2"
                    )
                  : "The gateway to Orchard Road shopping belt"}
              </span>
              <span className="text-xs text-[#1F284E] mb-4">
                {homePageAboutUsContent?.description
                  ? extractTextFromTags(homePageAboutUsContent.description, "p")
                  : "orchardgateway is a unique shopping mall located on Orchard Road, featuring a glass tubular bridge and an underpass, providing seamless access to the bustling shopping belt."}
              </span>
              <a href="/about-us">
                <div className="flex flex-row gap-x-2 mb-4 items-center cursor-pointer">
                  <span className="text-[#50A85A] text-xs">
                    Learn more about us
                  </span>
                  <img src={arrowRight} className="h-3 w-3" />
                </div>
              </a>

              <div className="rounded rounded-xl">
                <ImageLoader
                  src={getInformationAsset(
                    homePageAboutUsContent?.files[0]?.logo
                  )}
                  className="h-[450px] w-full rounded rounded-xl object-cover object-center"
                />
              </div>
            </div>
            <img
              src={doubleQuotation}
              className="absolute top-20 right-0 h-[140px] w-[140px]"
            />
          </div>
        </div>
        {/* desktop view */}
        <div className="hidden md:flex flex-row items-center justify-center bg-[#FAFBFF] w-full py-12 px-4 gap-x-8 xl:p-28 xl:gap-x-[120px]">
          <div className="aspect-w-4 aspect-h-3 rounded rounded-xl">
            <ImageLoader
              src={getInformationAsset(homePageAboutUsContent?.files[0]?.logo)}
              className="h-[510px] w-[384px] xl:h-[680px] xl:w-[512px] rounded rounded-3xl"
            />
          </div>

          <div className="relative">
            <div className="flex flex-col gap-y-6 xl:gap-y-10 relative z-10 xl:w-[560px]">
              <TitleSection title={"ABOUT US"} color={"border-[#408ECF]"} />
              <span className="text-2xl xl:text-[40px] text-[#1F284E] font-bold">
                {homePageAboutUsContent?.description
                  ? extractTextFromTags(
                      homePageAboutUsContent.description,
                      "h2"
                    )
                  : "The gateway to Orchard Road shopping belt"}
              </span>
              <span className="text-xl xl:text-2xl text-[#1F284E]">
                {homePageAboutUsContent?.description
                  ? extractTextFromTags(homePageAboutUsContent.description, "p")
                  : "orchardgateway is a unique shopping mall located on Orchard Road, featuring a glass tubular bridge and an underpass, providing seamless access to the bustling shopping belt."}
              </span>
              <a href="/about-us">
                <div className="flex flex-row gap-x-4">
                  <span className="text-[#50A85A] text-xl xl:text-2xl">
                    Learn more about us
                  </span>
                  <img src={arrowRight} />
                </div>
              </a>
            </div>
            <img
              src={doubleQuotation}
              className="absolute top-20 right-0 h-[150px] w-[150px] xl:h-[200px] xl:w-[200px]"
            />
          </div>
        </div>
      </div>
    );
  };

  const whatsOn = () => {
    return (
      <div>
        {/* mobile view */}
        <div className="py-6 flex flex-col md:hidden">
          <div className="flex flex-row justify-center px-4">
            <TitleSection title={"WHAT'S ON"} color={"border-[#C03C8F]"} />
          </div>
          <div className="flex flex-row justify-center items-center mt-4 px-4 xl:px-6">
            <span className="text-2xl font-bold text-center font-[Oplicio]">
              {homePageWhatsOnContent?.description
                ? extractTextFromTags(homePageWhatsOnContent.description, "h2")
                : "vibrant lineup of events and activities fills the mall with excitement!"}
            </span>
          </div>
          <ImageRowSlider
            datas={whatsOnPageContent}
            type={"whats-on"}
            urlTnc={getInformationAsset(dataPrivacyContent?.files?.[0]?.logo)}
          />
        </div>
        {/* desktop view */}
        <div className="py-16 hidden md:flex flex-col">
          <div className="flex flex-row justify-center px-20">
            <TitleSection title={"WHAT'S ON"} color={"border-[#C03C8F]"} />
          </div>
          <div className="flex flex-row justify-center items-center mt-6 px-20">
            <span className="text-2xl xl:text-[40px] font-bold text-center">
              {homePageWhatsOnContent?.description
                ? extractTextFromTags(homePageWhatsOnContent.description, "h2")
                : "vibrant lineup of events and activities fills the mall with excitement!"}
            </span>
          </div>
          <ImageRowSlider
            datas={whatsOnPageContent}
            type={"whats-on"}
            urlTnc={getInformationAsset(dataPrivacyContent?.files?.[0]?.logo)}
          />
        </div>
      </div>
    );
  };

  const directory = () => {
    return (
      <div>
        {/* desktop view */}
        <div className="hidden flex-row md:flex md:flex-col py-20 md:px-6 xl:px-[120px]">
          <TitleSection title={"DIRECTORY"} color={"border-[#EDA542]"} />

          <div className="flex flex-row justify-between py-7 items-center">
            <span className="text-black font-bold text-[40px]">
              {homePageDirectoryContent?.description
                ? extractTextFromTags(
                    homePageDirectoryContent.description,
                    "h2"
                  )
                : "Discover all Stores"}
            </span>
            <a href="/directory">
              <div className="flex flex-row gap-x-4 items-center cursor-pointer">
                <span className="text-[#50A85A] text-2xl">Explore More</span>
                <img src={arrowRight} className="w-6" />
              </div>
            </a>
          </div>

          <ContentSlider data={mapStore?.categoryPois} />
        </div>
        {/* mobile view */}
        <div className="flex flex-col md:hidden py-6 ">
          <div className="px-4">
            <TitleSection
              title={"DIRECTORY"}
              color={"border-[#EDA542]"}
              className={"mb-4 md:mb-[27px]"}
            />

            <span className="text-black font-bold text-2xl md:px-4">
              {homePageDirectoryContent?.description
                ? extractTextFromTags(
                    homePageDirectoryContent.description,
                    "h2"
                  )
                : "Discover all Stores"}
            </span>
          </div>

          <ImageRowSlider datas={mapStore?.categoryPois} type={"directory"} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover Orchardgateway, a premier shopping destination in the heart of Orchard Road, offering a unique blend of retail, dining, and entertainment experiences."
        />
      </Helmet>
      <Layout
        headerUrl={homePageImg && getInformationAsset(homePageImg)}
        headerUrlMobile={
          homePageMobileImg && getInformationAsset(homePageMobileImg)
        }
        headerHeight={"h-[620px] object-cover object-center md:h-[800px]"}
      >
        <div className="h-full">
          {explore()}

          {aboutUs()}

          {whatsOn()}

          {directory()}
        </div>
      </Layout>
    </>
  );
});

export default Home;
