import React, { useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import Layout from "../../components/Layout";
import PaginatedGridWhatsOn from "../../components/PaginationGridWhatsOn";
import { useLocation } from "react-router-dom";

import { AdsignMapContext } from "../../adsignMapProvider";
import { extractTextFromTags, getInformationAsset } from "../../helper";
import InstagramContent from "../../components/InstagramContent";
import { mapConfig } from "../../constants/mapConstant";
import { logAnalyticsEvent } from "../../services/firebaseAnalytics";
import { Helmet } from "react-helmet-async";

const WhatsOn = observer(() => {
  const mapStore = React.useContext(AdsignMapContext);

  const [whatsOnPageImg, setWhatsOnPageImg] = useState(null);
  const [whatsOnPageInstagramImg, setWhatsOnPageInstagramImg] = useState(null);
  const [whatsOnPageInstagramUsername, setWhatsOnPageInstagramUsername] =
    useState(null);
  const [whatsOnPageContent, setWhatsOnPageContent] = useState(null);

  const location = useLocation();

  useEffect(() => {
    logAnalyticsEvent("destination", {
      page_title: `whatsOn`,
    });
  }, []);

  useEffect(() => {
    // Extract the parameter from the URL (e.g., ?scrollTo=section1)
    const params = new URLSearchParams(location.search);
    const sectionId = params.get("scrollTo"); // The value of the scrollTo param

    if (sectionId) {
      // Scroll to the div with the corresponding id
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    if (mapStore && mapStore.information && mapStore.information.length > 0) {
      const whatsOnPageData = mapStore.information
        .filter((info) => {
          return info.name.toLowerCase().includes("what's on page");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      const landingPageData = whatsOnPageData.filter((data) => {
        return data.name.toLowerCase().includes("header - image");
      });

      if (landingPageData) {
        setWhatsOnPageImg(landingPageData[0].files[0].logo);
      }
    }
  }, [mapStore, mapStore.information]);

  useEffect(() => {
    if (mapStore && mapStore.medias && mapStore.medias.length > 0) {
      const instagramData = mapStore.medias.filter((data) => {
        return data.categoryName
          .toLowerCase()
          .includes("what's on - instagram media");
      });

      const contentData = mapStore.medias
        .filter((data) => {
          return data.categoryName
            .toLowerCase()
            .includes("events & promotions");
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      if (instagramData) {
        setWhatsOnPageInstagramImg(instagramData.slice(0, 8));
      }

      if (contentData) {
        setWhatsOnPageContent(contentData);
      }
    }
  }, [mapStore, mapStore.medias]);

  return (
    <>
      <Helmet>
        <meta name="description" content="Check out What’s On" />
      </Helmet>
      <Layout
        headerUrl={whatsOnPageImg && getInformationAsset(whatsOnPageImg)}
        title={"What’s On"}
        headerHeight={"h-[228px] md:h-[416px]"}
      >
        <div className="h-full p-4 xl:p-10 mb-8 xl:mb-20">
          <PaginatedGridWhatsOn data={whatsOnPageContent} />
        </div>
        <div className="px-4 pb-4 xl:px-6 xl:pb-6 pt-8 md:pt-12 xl:pt-24 bg-[#FAFBFF]">
          <center>
            <span
              id="instagramContent"
              className="text-2xl md:text-5xl font-bold items-center text-center"
            >
              {whatsOnPageInstagramUsername ?? "@orchardgatewaysg"}
            </span>
          </center>
          <InstagramContent whatsOnPageInstagram={whatsOnPageInstagramImg} />
        </div>
      </Layout>
    </>
  );
});

export default WhatsOn;
