export const mapConfig = {
  siteId: "67a2c862844ea24bd0f846d9",
  adsignId: "67a1efa9d691ca4895e6c2b9",
  channelId: "019564c9-4a43-7995-b441-8a37215148ab",
  platformType: "web",
  api: "https://api.adsign.adactive.asia",
  username: "screen@ogw.com",
  password: "Password12!12",
  assetUrl: "https://d49qdhygt0dif.cloudfront.net",
};

export const mapOption = {
  hideUserLocation: true,
  highlightColor: "#4999A3",
  isShowAllFloor: false,
  floorLabel: {
    color: "white",
    size: 5,
  },
  isDisplayMinimap: false,
};
